import React from "react"
import Layout from "../../layout"
import SiteHead from "../../head"
import Hero from "../../components/self-service-landing-page/components/Hero"
import { ColorSchemeProvider } from "../../components/self-service-landing-page/color-scheme-provider"
import { dataHero } from "../../components/calculator/data"

const heroWrapperCss = {
  "&&": {
    div: { maxWidth: "52rem" },

    h1: {
      maxWidth: "32ch",
    },
  },
}

const uCalc_368628 = _theme => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
})

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title="Gatsby Salary Calculator"
      description="Shape the future of Gatsby. Find your salary."
    />
  )
}

export default function SalaryCalculatorPage() {
  React.useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src =
      (document.location.protocol === "https:" ? "https:" : "http:") +
      "//ucalc.pro/api/widget.js?id=368628&t=" +
      Math.floor(new Date() / 18e5)

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <ColorSchemeProvider>
        <div css={heroWrapperCss}>
          <Hero {...dataHero} />
          <div className="uCalc_368628" css={uCalc_368628}></div>
        </div>
      </ColorSchemeProvider>
    </Layout>
  )
}
